import React from 'react';
import {getRoutes} from "../../components/shared/routes";
import Layout from "../../components/shared/Layout";
import SEO from "../../components/shared/SEO";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import DcodeApplyAndRead from "../../components/sections/DcodeApplyAndRead/DcodeApplyAndRead";


const DcodeApplyAndReadPage = () => {
    const routes = getRoutes();
    const pageTitle = routes.OfferingDcodeApplyAndRead.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <Breadcrumbs
                title={pageTitle}
                parentTitles={[routes.Offering.pageTitle]}
                parentRoutes={[routes.Offering]}
            />

            <DcodeApplyAndRead/>

        </Layout>
    );
};

export default DcodeApplyAndReadPage;
