import React from 'react';
import PageTitle from "../../shared/PageTitle/PageTitle";
import AdditionalInformation from "../../shared/additionalInformation/additionalInformation";
import Footer from "../../shared/Footer/Footer";
import BackToOfferingButton from "../../shared/ButtonBackTo/BackToOfferingButton";
import ContactForm2 from "../../shared/ContactForm/ContactForm2";
import {getRoutes} from "../../shared/routes";

const DcodeApplyAndRead = () => {
    const routes = getRoutes();
    const pageTitle = routes.OfferingDcodeApplyAndRead.pageTitle;

    return (
        <div>
            <PageTitle>{pageTitle}</PageTitle>
            <ContactForm2 websiteSection={pageTitle}/>
            <BackToOfferingButton/>

            <AdditionalInformation typeMail={'sales'}/>

            <Footer/>
        </div>
    );
};

export default DcodeApplyAndRead;
